<script lang="ts">
	import NotificationIcon from '$lib/components/Notification/NotificationIcon.svelte';
	import {
		NotificationType,
		notifications,
		type Notification
	} from '$lib/store/notification.store';
	import { fly, scale } from 'svelte/transition';
	import { backOut } from 'svelte/easing';
	import { getTranslate } from '@tolgee/svelte';

	const _typeToTextColor: Record<NotificationType, string> = {
		[NotificationType.Success]: 'text-green-success',
		[NotificationType.Warning]: 'text-orange-400',
		[NotificationType.Error]: 'text-red-500',
		[NotificationType.Info]: 'text-gray-500'
	};

	// Uses the original box height to create an empty container fixing the bubble up animation
	let _notificationContainer;
	let lastHeight = 0;
	$: if (_notificationContainer) {
		lastHeight = _notificationContainer.getBoundingClientRect().height;
	}

	let displayPlaceholder = false;
	// Scale the placeholder down to 0 from lastHeight to animate the removal
	let scaleDown = false;

	const { t } = getTranslate();

	export let notification: Notification;
</script>

{#if !notification.closed}
	<div
		bind:this={_notificationContainer}
		in:fly={{ x: 200, easing: backOut, duration: 800, delay: 400 }}
		out:fly={{ x: 200, easing: backOut, duration: 800 }}
		on:outroend={() => {
			// Spawn the placeholder and wait 200ms to scale it down
			displayPlaceholder = true;
			setTimeout(() => {
				scaleDown = true;
			}, 200);
		}}
		class="flex items-center gap-3 {_typeToTextColor[
			notification.type
		]} w-76 md:w-96 p-4 bg-white shadow-lg mt-2"
	>
		<NotificationIcon type={notification.type} />
		<div class="flex-1 break-all">
			{#if notification.isTolgee}
				{$t(notification.message)}
			{:else}
				{notification.message}
			{/if}
		</div>
		<button
			on:click={() => {
				if (notification.onClose) {
					notification.onClose();
				}
				notifications.removeNotification(notification);
			}}
		>
			<svg
				class="w-5 h-5"
				fill="currentColor"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fill-rule="evenodd"
					d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
					clip-rule="evenodd"
				/>
			</svg>
		</button>
	</div>
{/if}
{#if displayPlaceholder}
	<div
		out:scale
		style="height: {lastHeight}px"
		class="duration-500 mt-2 transition-[height,margin-top]{scaleDown ? ' !h-0 mt-0' : ''}"
	/>
{/if}
