<svg {...$$props} width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M10.8284 7.32843C11.5786 6.57828 12 5.56087 12 4.5C12 3.43913 11.5786 2.42172 10.8284 1.67157C10.0783 0.921427 9.06087 0.5 8 0.5C6.93913 0.5 5.92172 0.921427 5.17157 1.67157C4.42143 2.42172 4 3.43913 4 4.5C4 5.56087 4.42143 6.57828 5.17157 7.32843C5.92172 8.07857 6.93913 8.5 8 8.5C9.06087 8.5 10.0783 8.07857 10.8284 7.32843Z"
		stroke="#111928"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M3.05025 13.5503C4.36301 12.2375 6.14348 11.5 8 11.5C9.85652 11.5 11.637 12.2375 12.9497 13.5503C14.2625 14.863 15 16.6435 15 18.5H1C1 16.6435 1.7375 14.863 3.05025 13.5503Z"
		stroke="#111928"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
