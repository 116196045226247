import { get, writable } from 'svelte/store';
import { page } from '$app/stores';

function createVisitor() {
	const { subscribe, set, update } = writable<{ id?: string, anonId: string, isLoggedIn: boolean }>(
		{ anonId: crypto.randomUUID(), isLoggedIn: false }, () => {
		const unsubscribePage = page.subscribe((page) => {
			const id = page.data.session?.user.id;
		
			if (id) {
				set({ id: id, anonId: get(visitor).anonId, isLoggedIn: true });
			} else {
				set({ anonId: get(visitor).anonId, isLoggedIn: false })
			}
		});
	
		return () => unsubscribePage();
	});

	return {
		subscribe,
	};
}

export const visitor = createVisitor();
