<script lang="ts">
	import { screenSize } from '$lib/store/breakpoints.store';
	import deepskydata_logo_black from '$lib/assets/deepskydata_logo_black.png';
	import { classes } from '$lib/utils';
	import UserIcon from '../Icon/UserIcon.svelte';
	import UserOffIcon from '../Icon/UserOffIcon.svelte';
	import { logOut } from '$lib/service/supabase/user.service';
	import DropDownNavbar from '../DropDown/DropDownNavbar.svelte';
	import { T, getTranslate } from '@tolgee/svelte';
	import { visitor } from '$lib/store/visitorStore';
	import { page } from '$app/stores';
	import Button from '../Button/Button.svelte';
	import { browser } from '$app/environment';
	import { goto } from '$app/navigation';
	import MobileNavbar from './MobileNavbar.svelte';
	import LinkButton from '../Button/LinkButton.svelte';

	export let additionalClass = '';

	const { t } = getTranslate();

	const itemsLoggedIn = [{ text: 'logout', href: '#', handleClick: () => logOut('/') }];
	const itemsLoggedOut = [
		{ text: 'Log in', href: '/auth/login' },
		{ text: 'Sign up', href: '/auth/signup' }
	];
	const navigationItems = [
		{ text: 'Use Case Marketing', id: 'use-case-marketing' },
		{ text: 'Platform', href: $page.data.session?.user ? '/courses' : '/auth/signup' },
		{ text: 'Our Story', id: 'ourStory' }
	];

	let mobileNavbarOpen: boolean = false;

	$: isUsecaseMarketingPage = $page.url.pathname.includes('/use-case-marketing');
	$: navBarButtonUsecaseMarketingTitle = $t('navbar.button.usecase-marketing');
	$: navBarButtonPlatformTitle = $t('navbar.button.platform');
	$: navBarButtonOurStoryTitle = $t('navbar.button.our-story');
	$: navBarButtonContactUsTitle = $t('navbar.button.contact-us');
	$: navBarButtonLoginTitle = $t('navbar.button.login');
	$: isMobileOrTablet = $screenSize === 'mobile' || $screenSize === 'tablet';
	$: {
		if (!isMobileOrTablet) {
			mobileNavbarOpen = false;
		}
	}

	function handleButtonClick(buttonId: string) {
		if (!browser) return;

		const elementToScrollTo = document.getElementById(buttonId);

		if (elementToScrollTo) {
			const yOffset = -40;
			const y = elementToScrollTo.getBoundingClientRect().top + window.scrollY + yOffset;

			window.scrollTo({ top: y, behavior: 'smooth' });
		}
	}

	const handleMobileNavbarOpenToggle = () => {
		mobileNavbarOpen = !mobileNavbarOpen;
	};
</script>

<header class={classes(additionalClass, 'fixed top-0 z-index-navbar w-full shadow-md bg-white')}>
	<nav class="bg-light border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-900">
		<div class="flex justify-between items-center">
			<div class="flex justify-start items-center">
				<a href="/" class="flex mr-6">
					<img class="mr-3 h-12" src={deepskydata_logo_black} alt="deepskydata logo" />
				</a>
			</div>
			<div
				class="flex justify-between items-center lg:order-2 {isUsecaseMarketingPage &&
				isMobileOrTablet
					? 'space-x-2'
					: 'space-x-6'} text-base font-regular"
			>
				{#if isUsecaseMarketingPage}
					{#if isMobileOrTablet}
						<MobileNavbar
							isOpen={mobileNavbarOpen}
							on:buttonClick={handleMobileNavbarOpenToggle}
							on:scrollToButtonClick={(event) => {
								handleButtonClick(event.detail);
							}}
							{navigationItems}
						/>
					{:else}
						<button class="uppercase" on:click={() => handleButtonClick('use-case-marketing')}>
							{navBarButtonUsecaseMarketingTitle}
						</button>
						<button
							class="uppercase"
							on:click={() => {
								if ($page.data.session?.user) {
									goto('/courses');
								} else {
									goto('/auth/signup');
								}
							}}
						>
							{navBarButtonPlatformTitle}
						</button>
						<button class="uppercase" on:click={() => handleButtonClick('ourStory')}>
							{navBarButtonOurStoryTitle}
						</button>
						<LinkButton
							href="https://calendar.app.google/A83VFFRXSuJAzeJT7"
							additionalClass="!font-bold uppercase w-fit !px-10 !py-2"
						>
							{navBarButtonContactUsTitle}</LinkButton
						>
						<Button
							additionalClass="!font-bold uppercase w-fit !px-10 !py-2"
							on:click={() => goto('/auth/login')}>{navBarButtonLoginTitle}</Button
						>
					{/if}
				{/if}

				<!-- <a class="uppercase" href="/learning-paths"><T keyName="navbar_learning_paths" /></a> -->
				{#if !isUsecaseMarketingPage}
					<a class="uppercase" href="/courses"><T keyName="navbar_courses" /></a>
				{/if}

				{#if !isUsecaseMarketingPage || isMobileOrTablet}
					{#if $visitor.isLoggedIn}
						<div data-elbglobals="member_id:{$visitor.id};anon_id:{$visitor.anonId}" />
						<DropDownNavbar items={itemsLoggedIn}>
							<UserIcon />
						</DropDownNavbar>
					{:else}
						<div data-elbglobals="member_id:anon{$visitor.anonId};anon_id:anon{$visitor.anonId}" />
						<DropDownNavbar items={itemsLoggedOut}>
							<UserOffIcon />
						</DropDownNavbar>
					{/if}
				{/if}
			</div>
		</div>
	</nav>
</header>
