<script lang="ts">
	import { classes } from '$lib/utils';
	export let additionalClass = '';
</script>

<svg
	width="159"
	height="82"
	viewBox="0 0 159 82"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	class={classes(' ', additionalClass)}
>
	<path
		d="M26.2459 17.2959H17.7588V38.7597H26.2459C32.1389 38.7597 36.5342 34.0677 36.5342 28.0278C36.5342 21.9879 32.1389 17.2959 26.2459 17.2959ZM26.2459 34.7159H21.9724V21.344H26.2459C29.9403 21.344 32.5043 24.0742 32.5043 28.03C32.5043 31.9857 29.9403 34.7159 26.2459 34.7159Z"
		fill="white"
	/>
	<path
		d="M52.3195 34.7159H43.3132V29.9016H51.405V25.9136H43.3132V21.344H52.1678V17.2959H39.0996V38.764H52.3195V34.7159Z"
		fill="white"
	/>
	<path
		d="M68.3471 34.7159H59.3386V29.9016H67.4304V25.9136H59.3386V21.344H68.1932V17.2959H55.125V38.764H68.3471V34.7159Z"
		fill="white"
	/>
	<path
		d="M75.3685 31.711H79.1228C83.2445 31.711 86.4197 28.5215 86.4197 24.5034C86.4197 20.4854 83.2445 17.2959 79.1228 17.2959H71.1592V38.7597H75.3685V31.711ZM75.3685 21.2517H79.1228C80.924 21.2517 82.2381 22.6318 82.2381 24.5034C82.2381 26.3751 80.924 27.7552 79.1228 27.7552H75.3685V21.2517Z"
		fill="white"
	/>
	<path
		d="M95.8617 35.0551C93.2058 35.0551 91.7079 33.7673 90.9472 31.7712L87.3447 33.8897C88.5028 37.0792 91.3725 39.1891 95.7314 39.1891C100.28 39.1891 103.486 36.7358 103.486 32.7199C103.486 28.3327 99.883 27.1994 96.4942 26.124C93.257 25.1431 92.4344 24.4391 92.4344 23.1191C92.4344 21.8914 93.4729 20.9727 95.2442 20.9727C97.413 20.9727 98.5711 22.1082 99.336 23.733L102.877 21.6767C101.449 18.6761 98.7612 16.8667 95.2506 16.8667C91.5861 16.8667 88.2293 19.1977 88.2293 23.2457C88.2293 27.3239 91.4045 28.7061 94.6694 29.657C97.8467 30.5756 99.2805 31.1895 99.2805 32.7843C99.2805 33.9798 98.3959 35.0551 95.8617 35.0551Z"
		fill="white"
	/>
	<path
		d="M26.2459 43.0527H17.7588V64.5166H26.2459C32.1389 64.5166 36.5342 59.8246 36.5342 53.7846C36.5342 47.7447 32.1389 43.0527 26.2459 43.0527ZM26.2459 60.4728H21.9724V47.1008H26.2459C29.9403 47.1008 32.5043 49.831 32.5043 53.7868C32.5043 57.7426 29.9403 60.4706 26.2459 60.4706V60.4728Z"
		fill="white"
	/>
	<path
		d="M44.1387 43.0527L36.6602 64.5166H41.2092L42.4913 60.6531H51.0104L52.2924 64.5166H56.8736L49.3651 43.0527H44.1387ZM43.8331 56.6994L46.7647 47.9293L49.6963 56.6994H43.8331Z"
		fill="white"
	/>
	<path
		d="M71.1592 43.0503H55.1338V47.0984H61.0247V64.5184H65.2384V47.0984H71.1592V43.0503Z"
		fill="white"
	/>
	<path
		d="M76.9287 43.0527L69.4502 64.5166H73.9993L75.2813 60.6531H83.7983L85.0803 64.5166H89.6636L82.153 43.0527H76.9287ZM76.6232 56.6994L79.5548 47.9293L82.4842 56.6973L76.6232 56.6994Z"
		fill="white"
	/>
	<path
		d="M124.569 17.2959V21.344H134.65L120.022 36.036L113.838 27.723L121.746 17.2959H116.892L109.565 26.7722V17.2959H105.351V38.764H109.565V28.7061L117.197 38.764H117.304L91.6611 64.5184H97.6311L137.641 24.3489V34.467H141.671V17.2959H124.569Z"
		fill="white"
	/>
</svg>
