<script lang="ts">
	import { classes } from '$lib/utils';
	import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@rgossiaux/svelte-headlessui';

	export let items: { text: string; href: string; handleClick?: () => void }[];

	let noop = () => {};
</script>

<Menu as="div" class="relative inline-block text-left">
	<div>
		<MenuButton
			class="inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple focus:ring-offset-2 focus:ring-offset-gray-100"
		>
			<slot />
		</MenuButton>
	</div>

	<Transition
		enter="transition ease-out duration-100"
		enterFrom="transform opacity-0 scale-95"
		enterTo="transform opacity-100 scale-100"
		leave="transition ease-in duration-75"
		leaveFrom="transform opacity-100 scale-100"
		leaveTo="transform opacity-0 scale-95"
	>
		<MenuItems
			class="absolute right-0 z-index-navbar-dropdown mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
		>
			{#each items as item}
				<div
					class="py-1"
					on:click={() => (item.handleClick ? item.handleClick() : noop())}
					on:keydown={() => (item.handleClick ? item.handleClick() : noop())}
				>
					<MenuItem>
						<a
							href={item.href}
							class={classes('hover:bg-gray-100 text-gray-900 block px-4 py-2 text-sm')}
						>
							{item.text}
						</a>
					</MenuItem>
				</div>
			{/each}
		</MenuItems>
	</Transition>
</Menu>
