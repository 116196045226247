<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import { fly } from 'svelte/transition';
	import MobileMenuBackgroundBlur from '../Decorations/MobileMenuBackgroundBlur.svelte';
	import CustomLineDivider from '../Divider/CustomLineDivider.svelte';
	import MobileMenuCloseIcon from '../Icon/MobileMenuCloseIcon.svelte';
	import MobileMenuBurgerButtonIcon from '../Icon/MobileMenuBurgerButtonIcon.svelte';

	export let navigationItems: Array<{
		text: string;
		href?: string | null;
		id?: string | null;
	}> = [];
	export let isOpen: boolean = false;

	let closed: boolean = true;

	const dispatch = createEventDispatcher();

	const handleClick = (id?: string | null) => {
		closed = !closed;

		if (id) {
			dispatch('scrollToButtonClick', id);
		}

		dispatch('buttonClick');
	};
</script>

{#if isOpen}
	<div
		transition:fly|local={{ x: 400, duration: 400 }}
		class="absolute overflow-y-hidden bg-white top-[68px] pb-[138px] left-0 flex flex-col justify-center items-center gap-8 uppercase font-bold px-11 text-center text-4xl w-screen h-screen"
	>
		{#each navigationItems as navigationItem}
			{#if navigationItem.id}
				<button class="uppercase" on:click={() => handleClick(navigationItem.id)}
					>{navigationItem.text}</button
				>
			{:else if navigationItem.href}
				<a on:click={() => handleClick()} href={navigationItem.href}>{navigationItem.text}</a>
			{/if}
		{/each}

		<MobileMenuBackgroundBlur
			additionalClass="pointer-events-none absolute z-0 bottom-[-20%] right-0"
		/>
		<div class="absolute bottom-0 mt-auto pb-32">
			<CustomLineDivider
				additionalClass="ml-auto pr-14 -mb-5 right-16 bottom-16"
				width="1px"
				length="155px"
				color="#000000"
				orientation="vertical"
			/>
			<CustomLineDivider width="1px" length="90vw" color="#000000" />
		</div>
	</div>
{/if}
<button class={closed ? 'hidden' : 'block'} on:click={() => handleClick()}
	><MobileMenuCloseIcon /></button
>

<button class={closed ? 'block' : 'hidden'} on:click={() => handleClick()}
	><MobileMenuBurgerButtonIcon /></button
>
