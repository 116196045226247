<script lang="ts">
	import { classes } from '$lib/utils';
	export let additionalClass = '';
</script>

<svg
	width="100%"
	height="100%"
	viewBox="0 0 375 309"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	class={classes('', additionalClass)}
>
	<g filter="url(#filter0_f_9016_13015)">
		<circle
			cx="400.122"
			cy="327.426"
			r="128.695"
			transform="rotate(15 400.122 327.426)"
			fill="#8F00FF"
		/>
	</g>
	<g filter="url(#filter1_f_9016_13015)">
		<circle
			cx="189.526"
			cy="327.426"
			r="118.383"
			transform="rotate(15 189.526 327.426)"
			fill="#F1F780"
		/>
	</g>
	<g filter="url(#filter2_f_9016_13015)">
		<circle
			cx="242.502"
			cy="262.103"
			r="96.7277"
			transform="rotate(15 242.502 262.103)"
			fill="#F6B5B4"
		/>
	</g>
	<defs>
		<filter
			id="filter0_f_9016_13015"
			x="106.401"
			y="33.7046"
			width="587.441"
			height="587.442"
			filterUnits="userSpaceOnUse"
			color-interpolation-filters="sRGB"
		>
			<feFlood flood-opacity="0" result="BackgroundImageFix" />
			<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
			<feGaussianBlur stdDeviation="82.4969" result="effect1_foregroundBlur_9016_13015" />
		</filter>
		<filter
			id="filter1_f_9016_13015"
			x="-93.8806"
			y="44.0193"
			width="566.812"
			height="566.813"
			filterUnits="userSpaceOnUse"
			color-interpolation-filters="sRGB"
		>
			<feFlood flood-opacity="0" result="BackgroundImageFix" />
			<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
			<feGaussianBlur stdDeviation="82.4969" result="effect1_foregroundBlur_9016_13015" />
		</filter>
		<filter
			id="filter2_f_9016_13015"
			x="-19.2439"
			y="0.357925"
			width="523.492"
			height="523.491"
			filterUnits="userSpaceOnUse"
			color-interpolation-filters="sRGB"
		>
			<feFlood flood-opacity="0" result="BackgroundImageFix" />
			<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
			<feGaussianBlur stdDeviation="82.4969" result="effect1_foregroundBlur_9016_13015" />
		</filter>
	</defs>
</svg>
