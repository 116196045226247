<script lang="ts">
	import '../app.css';
	import { onMount } from 'svelte';
	import { TolgeeProvider, Tolgee, FormatSimple, DevTools } from '@tolgee/svelte';
	import { goto, invalidate } from '$app/navigation';

	import Footer from '$lib/components/PageSection/Footer.svelte';
	import Navbar from '$lib/components/Navigation/Navbar.svelte';
	import NotificationProvider from '$lib/components/Notification/NotificationProvider.svelte';

	import * as Sentry from '@sentry/svelte';
	import { BrowserTracing } from '@sentry/tracing';
	import { browser, dev } from '$app/environment';
	import { navigating, page } from '$app/stores';
	import {
		PUBLIC_ENVIRONMENT,
		PUBLIC_SENTRY_DSN,
		PUBLIC_TOLGEE_API_KEY,
		PUBLIC_TOLGEE_API_URL
	} from '$env/static/public';
	import * as walker from '@elbwalker/walker.js';
	// TODO: Fix prerendering with dynamic language switching - just don't await stuff incorrectly :'D
	// Did not work after release - Schuwa what have I done wrong here?
	import enLanguage from '../i18n/en.json';
	import { redirectOnUserMetaDataMissing } from '$lib/service/loginRedirects';
	import { LOGIN_SOCIAL, SIGN_UP_SOCIAL } from '$lib/constants';

	export let data;

	$: ({ supabase, session } = data);

	// Tell the walker to run everytime a route changed.
	// Except when the route is a CDP - we need to wait for the
	// necessary event payload data before starting the run on
	// the CDP itself.
	$: if ($navigating && browser && PUBLIC_ENVIRONMENT.includes('prd') && walker.elb) {
		if (!$navigating?.to?.route?.id?.includes('/courses/[slug]')) {
			walker.elb('walker run');
		}
	}

	if (PUBLIC_SENTRY_DSN && !dev) {
		Sentry.init({
			dsn: PUBLIC_SENTRY_DSN,
			integrations: [new BrowserTracing()],
			tracesSampleRate: 1
		});
	}

	onMount(() => {
		const {
			data: { subscription }
		} = supabase.auth.onAuthStateChange((_, _session) => {
			if (_session?.expires_at !== session?.expires_at) {
				invalidate('supabase:auth');
			}
		});

		if (localStorage.getItem(SIGN_UP_SOCIAL)) {
			localStorage.removeItem(SIGN_UP_SOCIAL);
			goto('/auth/signup?signup=social');
		}

		if (localStorage.getItem(LOGIN_SOCIAL)) {
			console.log('USER: ', $page.data.session?.user);
			if ($page.data.session?.user) {
				// Redirect back to sign up if a social login user has missing meta data.
				redirectOnUserMetaDataMissing($page.data.session?.user);
			}
		}

		if (PUBLIC_ENVIRONMENT.includes('prd')) {
			window.elbwalker = walker.default({ default: true });
		}

		return () => subscription.unsubscribe();
	});

	const tolgee = Tolgee()
		.use(DevTools())
		.use(FormatSimple())
		.init({
			language: 'en',
			apiUrl: PUBLIC_TOLGEE_API_URL,
			apiKey: PUBLIC_TOLGEE_API_KEY,
			fallbackLanguage: 'en',
			staticData: {
				en: enLanguage
			}
		});

	if (PUBLIC_ENVIRONMENT !== 'prd') {
		import('@tolgee/web/tools').then(({ InContextTools }) => {
			tolgee.addPlugin(InContextTools());
		});
	}
</script>

<svelte:head>
	<script async src="/scripts/piwik.js"></script>
</svelte:head>

<TolgeeProvider {tolgee}>
	<Navbar />
	<div class="layout">
		<slot />
	</div>

	<Footer />
	<NotificationProvider />
</TolgeeProvider>
