<script lang="ts">
	import { classes } from '$lib/utils';
	export let additionalClass = '';
</script>

<svg
	class={classes('w-7 h-6', additionalClass)}
	height="100%"
	width="100%"
	version="1.1"
	viewBox="0 0 22 16"
	fill="currentColor"
	fill-rule="evenodd"
	clip-rule="evenodd"
	><use xlink:href="#ytp-id-50" />
	<rect y="0.0500488" width="22" height="1.3" fill="black" />
	<rect y="7.3501" width="22" height="1.3" fill="black" />
	<rect y="14.65" width="12" height="1.3" fill="black" />
</svg>
