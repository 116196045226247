<script lang="ts">
	import { notifications } from '$lib/store/notification.store';
	import NotificationBubble from '$lib/components/Notification/NotificationBubble.svelte';
</script>

<div class="fixed z-[100] md:right-10 md:top-10 top-5 right-5 max-w-md grid">
	{#each $notifications as notification}
		<NotificationBubble {notification} />
	{/each}
</div>
