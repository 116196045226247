<script>
	import LogoWhite from '../Icon/LogoWhite.svelte';
	import { getTranslate } from '@tolgee/svelte';

	const { t } = getTranslate();
</script>

<footer class="bg-black w-full flex lg:flex-row place-items-center py-12">
	<div class="pl-6 flex flex-col md:flex-row md:w-full">
		<div class="flex-0 mr-auto -ml-4">
			<LogoWhite additionalClass="" />
		</div>
		<!-- TODO: This is not used anywhere - so for now, outcomment - ask Sabrina about it when she's back from vacation -->
		<!-- <div class="flex-1 flex flex-col pl-8 pr-4 lg:flex-row place-items-center justify-between">
			<div class="flex flex-col text-white text-center lg:text-left font-normal">
				<p class="font-normal">{@html $t('footer.address')}</p>
				<p class="font-normal">{@html $t('footer.email')}</p>
			</div>
		</div> -->
		<div
			class="flex-0 flex flex-col gap-6 md:flex-row md:place-items-center md:ml-auto pt-6 lg:pt-0 text-white font-normal mr-auto md:mr-11"
		>
			<a href="/legal/policy" class="hover:underline">Privacy Policy</a>
			<a href="/legal/terms" class="hover:underline">Terms & Conditions</a>
			<a href="/legal/imprint" class="hover:underline">Imprint</a>
		</div>
	</div>
</footer>
