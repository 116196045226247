<script lang="ts">
	import { classes } from '$lib/utils';
	export let additionalClass = '';
</script>

<svg
	class={classes('w-9 h-9', additionalClass)}
	height="100%"
	width="100%"
	version="1.1"
	viewBox="0 0 32 32"
	fill="currentColor"
	fill-rule="evenodd"
	clip-rule="evenodd"
	><use xlink:href="#ytp-id-50" />
	<rect
		x="8.67969"
		y="7.31848"
		width="22"
		height="1.3"
		transform="rotate(45 8.67969 7.31848)"
		fill="black"
	/>
	<rect
		x="7.76172"
		y="22.876"
		width="22"
		height="1.3"
		transform="rotate(-45 7.76172 22.876)"
		fill="black"
	/>
</svg>
