<svg
	width="15"
	height="19"
	viewBox="0 0 15 19"
	xmlns="http://www.w3.org/2000/svg"
	stroke="currentColor"
>
	<path
		d="M10.3284 7.32843C11.0786 6.57828 11.5 5.56087 11.5 4.5C11.5 3.43913 11.0786 2.42172 10.3284 1.67157C9.57828 0.921427 8.56087 0.5 7.5 0.5C6.43913 0.5 5.42172 0.921427 4.67157 1.67157C3.92143 2.42172 3.5 3.43913 3.5 4.5C3.5 5.56087 3.92143 6.57828 4.67157 7.32843C5.42172 8.07857 6.43913 8.5 7.5 8.5C8.56087 8.5 9.57828 8.07857 10.3284 7.32843Z"
		stroke="#111928"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M2.55025 13.5503C3.86301 12.2375 5.64348 11.5 7.5 11.5C9.35652 11.5 11.137 12.2375 12.4497 13.5503C13.7625 14.863 14.5 16.6435 14.5 18.5H0.5C0.5 16.6435 1.2375 14.863 2.55025 13.5503Z"
		stroke="#111928"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
</svg>
