<script lang="ts">
	import { NotificationType } from '$lib/store/notification.store';

	export let type: NotificationType;

	const _typeToBackground: Record<NotificationType, string> = {
		[NotificationType.Info]: 'bg-gray-500',
		[NotificationType.Success]: 'bg-green-success',
		[NotificationType.Warning]: 'bg-orange-400',
		[NotificationType.Error]: 'bg-red-500'
	};
</script>

<div class="rounded-full p-1 {_typeToBackground[type]}">
	{#if type === NotificationType.Success}
		<svg class="w-6 h-6" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
				clip-rule="evenodd"
			/>
		</svg>
	{:else if type === NotificationType.Warning}
		<svg class="w-6 h-6" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
				clip-rule="evenodd"
				fill="white"
			/>
		</svg>
	{:else if type === NotificationType.Error}
		<svg class="w-6 h-6" fill="white" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
				clip-rule="evenodd"
			/>
		</svg>
	{/if}
</div>
